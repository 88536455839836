import React from 'react';
import { Link } from 'react-router-dom';
import '../style/NotFound.css';

function NotFound() {
  return (
    <div className="not-found">
      <div className="not-found-content">
        <div className="error-code">404</div>
        <h1>Oops! Page Not Found</h1>
        <p>The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.</p>
        
        <div className="illustration">
          <i className="fas fa-school school-icon"></i>
          <div className="dots">
            <span></span>
            <span></span>
            <span></span>
          </div>
          <i className="fas fa-search search-icon"></i>
        </div>

        <div className="helpful-links">
          <h2>Here are some helpful links:</h2>
          <div className="links-grid">
            <Link to="/" className="link-card">
              <i className="fas fa-home"></i>
              <span>Home</span>
            </Link>
            <Link to="/about" className="link-card">
              <i className="fas fa-info-circle"></i>
              <span>About Us</span>
            </Link>
            <Link to="/contact" className="link-card">
              <i className="fas fa-envelope"></i>
              <span>Contact</span>
            </Link>
            <Link to="/admission" className="link-card">
              <i className="fas fa-user-plus"></i>
              <span>Admission</span>
            </Link>
          </div>
        </div>

        <Link to="/" className="back-home">
          <i className="fas fa-arrow-left"></i> Back to Homepage
        </Link>
      </div>
    </div>
  );
}

export default NotFound; 