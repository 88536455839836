import React from 'react';
import '../style/About.css';

function About() {
  return (
    <div className="page about">
      <div className="about-hero">
        <h1>About Us</h1>
        <p>Education Beyond the Classroom</p>
      </div>

      <section className="about-intro">
        <div className="intro-content">
          <h2>Welcome to MWL GM MEMORIAL</h2>
          <p>Parents and students share a range of meaningful experiences as happy, active members of the MWL GM Memorial Pre and Primary School. Our dedicated team builds on children's natural curiosity and their limitless desire to learn, helping them cultivate their strengths and figure out what matters to them.</p>
        </div>
        <div className="intro-image">
          <img src="https://mwlgmmemorial.sc.tz/wp-content/uploads/2024/06/MIP08646-768x432.jpg" alt="School Building" />
        </div>
      </section>

      <section className="mission-vision">
        <div className="mission-box">
          <i className="fas fa-bullseye"></i>
          <h3>Our Mission</h3>
          <p>To providing quality, holistic education that fosters academic success, character development, and environmental responsibility.</p>
        </div>
        <div className="vision-box">
          <i className="fas fa-eye"></i>
          <h3>Our Vision</h3>
          <p>To inspire and empower every child to become a lifelong learner, fostering curiosity, creativity, and compassion while developing the skills necessary to thrive in a diverse and changing world.</p>
        </div>
      </section>

      <section className="core-values">
        <h2>Our Approach</h2>
        <div className="values-grid">
          <div className="value-item">
            <i className="fas fa-graduation-cap"></i>
            <h4>Highly Experienced</h4>
            <p>Parents and students share meaningful experiences as happy, active members of our school community</p>
          </div>
          <div className="value-item">
            <i className="fas fa-puzzle-piece"></i>
            <h4>Play-Based Learning</h4>
            <p>A purposeful, intentional use of play in our classes invites the young child to explore their world</p>
          </div>
          <div className="value-item">
            <i className="fas fa-users"></i>
            <h4>Dedicated Team</h4>
            <p>We help children cultivate their strengths and figure out what matters to them</p>
          </div>
          <div className="value-item">
            <i className="fas fa-heart"></i>
            <h4>Service & Diversity</h4>
            <p>Promoting equity and diversity in all aspects of our educational approach</p>
          </div>
        </div>
      </section>

      <section className="facilities">
        <h2>Our Curriculum</h2>
        <div className="facilities-grid">
          <div className="facility-card">
            <img src="https://mwlgmmemorial.sc.tz/wp-content/uploads/2024/11/IMG_3802-1.webp" alt="Academic Programs" />
            <h4>Academic Excellence</h4>
            <p>Our curriculum aligns with Tanzanian national standards, focusing on language, mathematics, science, and social studies</p>
          </div>
          <div className="facility-card">
            <img src="https://mwlgmmemorial.sc.tz/wp-content/uploads/2024/11/IMG_3807-1.webp" alt="Extra Activities" />
            <h4>Extracurricular Activities</h4>
            <p>We offer sports, arts, music, and community service, encouraging students to explore their passions</p>
          </div>
          <div className="facility-card">
            <img src="https://mwlgmmemorial.sc.tz/wp-content/uploads/2024/11/IMG_3833.webp" alt="Learning Environment" />
            <h4>Learning Environment</h4>
            <p>We promote bilingual literacy, critical thinking, creativity, and responsible citizenship through hands-on learning</p>
          </div>
        </div>
      </section>

      <section className="achievements">
        <h2>Our Goals</h2>
        <div className="achievements-grid">
          <div className="achievement-item">
            <i className="fas fa-heart"></i>
            <h3>Love for Learning</h3>
            <p>Inspire passion for knowledge</p>
          </div>
          <div className="achievement-item">
            <i className="fas fa-shield-alt"></i>
            <h3>Integrity</h3>
            <p>Instill responsibility and ethics</p>
          </div>
          <div className="achievement-item">
            <i className="fas fa-star"></i>
            <h3>Excellence</h3>
            <p>Promote academic achievement</p>
          </div>
          <div className="achievement-item">
            <i className="fas fa-users"></i>
            <h3>Citizenship</h3>
            <p>Prepare well-rounded citizens</p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default About;
