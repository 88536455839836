import React from 'react';
import '../style/EarlyYears.css';

function EarlyYears() {
  return (
    <div className="page early-years">
      <div className="early-years-hero">
        <h1>Early Years</h1>
        <p>Building Strong Foundations for Lifelong Learning</p>
      </div>

      <section className="program-intro">
        <div className="intro-content">
          <h2>Our Early Years Program</h2>
          <p>Our curriculum focuses on fostering early development and learning through engaging, play-based activities. Here's how we support our infants in capturing essential skills during their early learning journey.</p>
        </div>
      </section>

      <section className="learning-areas">
        <h2>Key Learning Areas</h2>
        <div className="areas-grid">
          <div className="area-card">
            <i className="fas fa-brain"></i>
            <h3>Cognitive Development</h3>
            <p>Through play-based learning, children develop problem-solving skills, critical thinking, and creativity.</p>
          </div>
          <div className="area-card">
            <i className="fas fa-comments"></i>
            <h3>Language Development</h3>
            <p>Focus on both English and Kiswahili language acquisition through stories, songs, and interactive activities.</p>
          </div>
          <div className="area-card">
            <i className="fas fa-hands"></i>
            <h3>Motor Skills</h3>
            <p>Development of fine and gross motor skills through physical activities and hands-on learning.</p>
          </div>
          <div className="area-card">
            <i className="fas fa-heart"></i>
            <h3>Social-Emotional Growth</h3>
            <p>Building confidence, independence, and positive relationships with peers and teachers.</p>
          </div>
        </div>
      </section>

      <section className="daily-activities">
        <h2>Daily Activities</h2>
        <div className="activities-grid">
          <div className="activity-item">
            <img src="https://mwlgmmemorial.sc.tz/wp-content/uploads/2024/10/IMG_3957-300x200.webp" alt="Circle Time" />
            <h4>Circle Time</h4>
            <p>Interactive group sessions for stories, songs, and learning activities</p>
          </div>
          <div className="activity-item">
            <img src="https://mwlgmmemorial.sc.tz/wp-content/uploads/2024/10/IMG_4022-1536x1024.webp" alt="Creative Play" />
            <h4>Creative Play</h4>
            <p>Art, music, and imaginative play to foster creativity</p>
          </div>
          <div className="activity-item">
            <img src="https://mwlgmmemorial.sc.tz/wp-content/uploads/2024/11/IMG_3896.webp" alt="Outdoor Learning" />
            <h4>Outdoor Learning</h4>
            <p>Physical activities and nature exploration</p>
          </div>
        </div>
      </section>

      <section className="class-structure">
        <h2>Class Structure</h2>
        <div className="structure-content">
          <div className="age-group">
            <h3>Baby Class (Age 3-4)</h3>
            <ul>
              <li>Introduction to basic concepts</li>
              <li>Focus on social skills</li>
              <li>Play-based learning activities</li>
            </ul>
          </div>
          <div className="age-group">
            <h3>Kindergarten (Age 4-5)</h3>
            <ul>
              <li>Advanced language development</li>
              <li>Introduction to numbers and letters</li>
              <li>Structured learning activities</li>
            </ul>
          </div>
          <div className="age-group">
            <h3>Pre-Primary (Age 5-6)</h3>
            <ul>
              <li>Preparation for primary school</li>
              <li>Reading and writing foundations</li>
              <li>Basic mathematics concepts</li>
            </ul>
          </div>
        </div>
      </section>

      <section className="parent-involvement">
        <h2>Parent Partnership</h2>
        <div className="partnership-content">
          <div className="partnership-item">
            <i className="fas fa-calendar-alt"></i>
            <h4>Regular Updates</h4>
            <p>Weekly progress reports and monthly newsletters</p>
          </div>
          <div className="partnership-item">
            <i className="fas fa-users"></i>
            <h4>Parent Meetings</h4>
            <p>Scheduled consultations with teachers</p>
          </div>
          <div className="partnership-item">
            <i className="fas fa-home"></i>
            <h4>Home Learning Support</h4>
            <p>Resources and activities for home practice</p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default EarlyYears;
