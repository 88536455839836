import React from 'react';
import '../style/PrimaryLearning.css';

function PrimaryLearning() {
  return (
    <div className="page primary-learning">
      <div className="primary-hero">
        <h1>Primary Learning</h1>
        <p>Nurturing Excellence in Primary Education</p>
      </div>

      <section className="curriculum-intro">
        <div className="intro-content">
          <h2>Our Primary School Program</h2>
          <p>Our primary school curriculum follows the Tanzanian national curriculum while incorporating innovative teaching methods and modern educational practices. We focus on developing well-rounded students through a combination of academic excellence and character development.</p>
        </div>
      </section>

      <section className="subjects-section">
        <h2>Core Subjects</h2>
        <div className="subjects-grid">
          <div className="subject-card">
            <i className="fas fa-book"></i>
            <h3>Languages</h3>
            <ul>
              <li>English Language</li>
              <li>Kiswahili</li>
              <li>French (Optional)</li>
            </ul>
          </div>
          <div className="subject-card">
            <i className="fas fa-calculator"></i>
            <h3>Mathematics</h3>
            <ul>
              <li>Basic Mathematics</li>
              <li>Advanced Mathematics</li>
              <li>Mental Mathematics</li>
            </ul>
          </div>
          <div className="subject-card">
            <i className="fas fa-flask"></i>
            <h3>Science & Technology</h3>
            <ul>
              <li>Science</li>
              <li>ICT</li>
              <li>Environmental Studies</li>
            </ul>
          </div>
          <div className="subject-card">
            <i className="fas fa-globe-africa"></i>
            <h3>Social Studies</h3>
            <ul>
              <li>Geography</li>
              <li>History</li>
              <li>Civics</li>
            </ul>
          </div>
        </div>
      </section>

      <section className="teaching-approach">
        <h2>Our Teaching Approach</h2>
        <div className="approach-grid">
          <div className="approach-item">
            <img src="https://mwlgmmemorial.sc.tz/wp-content/uploads/2024/11/IMG_3867.webp" alt="Interactive Learning" />
            <h4>Interactive Learning</h4>
            <p>Our teachers use modern teaching methodologies to ensure active participation and engagement</p>
          </div>
          <div className="approach-item">
            <img src="https://mwlgmmemorial.sc.tz/wp-content/uploads/2024/11/IMG_3870.webp" alt="Practical Application" />
            <h4>Practical Learning</h4>
            <p>Students learn through hands-on experiences and real-world applications</p>
          </div>
          <div className="approach-item">
            <img src="https://mwlgmmemorial.sc.tz/wp-content/uploads/2024/11/IMG_3871.webp" alt="Individual Attention" />
            <h4>Personalized Attention</h4>
            <p>Small class sizes ensure each student receives individual support</p>
          </div>
        </div>
      </section>

      <section className="assessment-section">
        <h2>Assessment and Progress</h2>
        <div className="assessment-content">
          <div className="assessment-item">
            <i className="fas fa-chart-line"></i>
            <h4>Continuous Assessment</h4>
            <p>Regular tests and assignments to monitor progress</p>
          </div>
          <div className="assessment-item">
            <i className="fas fa-file-alt"></i>
            <h4>National Examinations</h4>
            <p>Preparation for NECTA examinations</p>
          </div>
          <div className="assessment-item">
            <i className="fas fa-comments"></i>
            <h4>Parent Communication</h4>
            <p>Regular updates on student performance</p>
          </div>
        </div>
      </section>

      <section className="facilities-section">
        <h2>Learning Resources</h2>
        <div className="facilities-grid">
          <div className="facility-item">
            <i className="fas fa-laptop"></i>
            <h4>Modern Technology</h4>
            <p>Access to computers and digital learning tools</p>
          </div>
          <div className="facility-item">
            <i className="fas fa-book-open"></i>
            <h4>Library Resources</h4>
            <p>Extensive collection of books and learning materials</p>
          </div>
          <div className="facility-item">
            <i className="fas fa-microscope"></i>
            <h4>Science Laboratory</h4>
            <p>Well-equipped lab for practical science learning</p>
          </div>
          <div className="facility-item">
            <i className="fas fa-futbol"></i>
            <h4>Sports Facilities</h4>
            <p>Various sports and recreational facilities</p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default PrimaryLearning;
