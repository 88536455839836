import React from 'react';
import './Watermark.css';

function Watermark() {
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  
  const handleContact = () => {
    if (isMobile) {
      window.location.href = "https://wa.me/255623391284?text=Hi,%20I%20saw%20your%20work%20on%20MWL%20GM%20Memorial%20website.%20I'm%20interested%20in%20your%20web%20development%20services.";
    } else {
      window.location.href = "mailto:meenacharles22@gmail.com?subject=Web%20Development%20Services&body=Hi,%20I%20saw%20your%20work%20on%20MWL%20GM%20Memorial%20website.%20I'm%20interested%20in%20your%20web%20development%20services.";
    }
  };

  return (
    <div className="watermark" onClick={handleContact}>
      <div className="watermark-content">
        <i className={isMobile ? "fab fa-whatsapp" : "fas fa-envelope"}></i>
        <div className="watermark-text">
          <p>Developed by <span className="pearlk-link">PearlK Tech</span></p>
          <small>{isMobile ? "Tap to chat" : "Click to email"}</small>
        </div>
      </div>
    </div>
  );
}

export default Watermark; 