import React from 'react';
import '../style/Sponsor.css';

function Sponsor() {
  return (
    <div className="page sponsor">
      <div className="sponsor-hero">
        <h1>Support A Child</h1>
        <p>Make a difference in a child's life through education</p>
      </div>

      <section className="support-intro">
        <div className="intro-content">
          <h2>How To Support Us</h2>
          <div className="intro-cards">
            <div className="intro-card">
              <i className="fas fa-hand-holding-heart"></i>
              <p>We urgently need your support and funds to continue providing free, high-quality education to empower and uplift our community's most disadvantaged children.</p>
            </div>
            <div className="intro-card">
              <i className="fas fa-globe-africa"></i>
              <p>Join us in making a tangible difference in the world by supporting us, where every donation directly impacts lives and fosters positive change.</p>
            </div>
          </div>
        </div>
      </section>

      <section className="sponsorship-info">
        <div className="info-content">
          <h3><i className="fas fa-gift"></i> What Your Sponsorship Provides</h3>
          <p className="info-subtitle">When you sponsor a pupil at MWL GM MEMORIAL PRE AND PRIMARY SCHOOL you are helping to fund their education.</p>
          
          <div className="benefits-grid">
            <div className="benefit-box">
              <i className="fas fa-book"></i>
              <h4>Educational Materials</h4>
              <p>Books, stationery, and learning resources</p>
            </div>
            <div className="benefit-box">
              <i className="fas fa-tshirt"></i>
              <h4>School Uniforms</h4>
              <p>Complete set of school attire</p>
            </div>
            <div className="benefit-box">
              <i className="fas fa-utensils"></i>
              <h4>Nutritious Meals</h4>
              <p>Daily hot and balanced meals</p>
            </div>
            <div className="benefit-box">
              <i className="fas fa-heartbeat"></i>
              <h4>Health Insurance</h4>
              <p>Medical care coverage</p>
            </div>
            <div className="benefit-box">
              <i className="fas fa-home"></i>
              <h4>Safe Environment</h4>
              <p>Clean and secure facilities</p>
            </div>
            <div className="benefit-box">
              <i className="fas fa-lightbulb"></i>
              <h4>Basic Utilities</h4>
              <p>Reliable electricity and water</p>
            </div>
          </div>
        </div>
      </section>

      <section className="sponsorship-plans">
        <h2>Sponsorship Options</h2>
        <div className="plans-grid">
          <div className="plan-card">
            <div className="plan-header">
              <i className="fas fa-child"></i>
              <h3>Baby Class Support</h3>
              <p className="price">400 USD</p>
            </div>
            <div className="plan-content">
              <p>Covers the first 3 baby class years</p>
              <p>Full academic scholarship</p>
              <button onClick={() => window.location.href='#payment'}>Sponsor Now</button>
            </div>
          </div>

          <div className="plan-card featured">
            <div className="plan-header">
              <i className="fas fa-graduation-cap"></i>
              <h3>Primary Education</h3>
              <p className="price">500 USD</p>
            </div>
            <div className="plan-content">
              <p>Covers Class One to Six</p>
              <p>Complete primary education support</p>
              <button onClick={() => window.location.href='#payment'}>Sponsor Now</button>
            </div>
          </div>

          <div className="plan-card">
            <div className="plan-header">
              <i className="fas fa-heart"></i>
              <h3>Monthly Support</h3>
              <p className="price">33-44 USD/month</p>
            </div>
            <div className="plan-content">
              <p>Flexible monthly contribution</p>
              <p>Ongoing support option</p>
              <button onClick={() => window.location.href='#payment'}>Sponsor Now</button>
            </div>
          </div>
        </div>
      </section>

      <section id="payment" className="payment-info">
        <h2>Payment Information</h2>
        <div className="payment-details">
          <div className="bank-info">
            <h3><i className="fas fa-university"></i> Bank Transfer Details</h3>
            <p><strong>Bank Account:</strong> 015C871537500</p>
            <p><strong>Account Name:</strong> MWL GM MEMORIAL PRE AND PRIMARY SCHOOL</p>
            <p><strong>Swift Code:</strong> CORUTZTZ</p>
            <p><strong>Bank:</strong> CRDB BANK</p>
            <p><strong>Address:</strong> 355 USA RIVER, ARUSHA</p>
          </div>
          <div className="contact-support">
            <h3><i className="fas fa-phone-alt"></i> Need Help?</h3>
            <p>Contact us for any assistance with your donation:</p>
            <p>Mobile: (255) - 788 476 876</p>
            <p>Email: info@mwlgmmemorial.sc.tz</p>
          </div>
        </div>
      </section>

      <section className="sponsor-benefits">
        <h2>As a Sponsor, You'll Receive</h2>
        <div className="benefits-grid">
          <div className="benefit-item">
            <i className="fas fa-envelope"></i>
            <h4>Regular Updates</h4>
            <p>Academic progress reports and photos</p>
          </div>
          <div className="benefit-item">
            <i className="fas fa-pen"></i>
            <h4>Personal Letters</h4>
            <p>Correspondence from your sponsored child</p>
          </div>
          <div className="benefit-item">
            <i className="fas fa-camera"></i>
            <h4>Photo Updates</h4>
            <p>Regular photographs of the child's progress</p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Sponsor; 