import React from 'react';
import '../style/Home.css';

function Home() {
  return (
    <div className="page home">
      <div className="hero-section">
        <font color="white"><h1>MWL GM MEMORIAL</h1></font>
        <p className="tagline">PRE AND PRIMARY SCHOOL</p>
        <div className="hero-cta">
          <button onClick={() => window.location.href='/contact'}>
            Schedule a Visit
          </button>
        </div>
      </div>

      <section className="features-section">
        <div className="feature-card">
          <img 
            src="https://mwlgmmemorial.sc.tz/wp-content/uploads/2024/11/IMG_3975-1.webp" 
            alt="Our Curriculum" 
          />
          <h3>OUR CURRICULUM</h3>
          <p>Our curriculum aligns with Tanzanian national standards, focusing on language, mathematics, science, and social studies.</p>
        </div>
        <div className="feature-card">
          <img 
            src="https://mwlgmmemorial.sc.tz/wp-content/uploads/2024/11/IMG_3991-1.webp" 
            alt="Extracurricular Activities" 
          />
          <h3>EXTRACURRICULAR ACTIVITES</h3>
          <p>Our motto, "Education Beyond the Classroom," is exemplified through a vibrant range of extracurricular activities designed to enrich our students' educational experiences</p>
        </div>
        <div className="feature-card">
          <img 
            src="https://mwlgmmemorial.sc.tz/wp-content/uploads/2024/11/IMG_4061-1.webp" 
            alt="School Values" 
          />
          <h3>OUR VALUES</h3>
          <p>Our goal is to prepare students for academic success and meaningful community engagement.</p>
        </div>
      </section>

      <section className="welcome-message">
        <div className="message-content">
          <h2>Growing Together, Learning Forever</h2>
          <p>At MWL GM Memorial Pre and Primary School, we believe every child is unique and deserves an education that nurtures their individual talents. Our comprehensive curriculum combines academic excellence with creative expression, ensuring your child receives the best possible start in their educational journey.</p>
        </div>
        <div className="message-image">
          <img 
            src="https://mwlgmmemorial.sc.tz/wp-content/uploads/2024/11/MIP08725.webp" 
            alt="Our School" 
          />
        </div>
      </section>

      <section className="highlights">
        <h2>Why Choose Us?</h2>
        <div className="highlights-grid">
          <div className="highlight-item">
            <i className="fas fa-users"></i>
            <h4>OUR VALUES</h4>
            <p>Our curriculum focuses on fostering early development and learning through engaging, play-based activities.
              Here’s how we support our infants in capturing essential skills during their early learning journey
             </p>
          </div>
          <div className="highlight-item">
            <i className="fas fa-book-reader"></i>
            <h4>Our Mission</h4>
            <p>To providing quality, holistic education that fosters academic success, 
            character development,
             and environmental responsibility
             </p>
          </div>
          <div className="highlight-item">
            <i className="fas fa-shield-alt"></i>
            <h4>Our Vision</h4>
            <p>
              To inspire and empower every child to become a lifelong learner,
               fostering curiosity, creativity, 
              and compassion while developing the skills necessary to thrive in a diverse and changing world.
            </p>
          </div>
          <div className="highlight-item">
            <i className="fas fa-globe"></i>
            <h4>Our Goals</h4>
            <p>
              To inspire a love for learning, instill integrity and responsibility, 
              promote academic excellence, 
              and prepare students as engaged, well-rounded citizens.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Home;

