import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLearningDropdownOpen, setIsLearningDropdownOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header>
      <div className="logo">
        <Link to="/">
          <img 
            src="https://mwlgmmemorial.sc.tz/wp-content/uploads/2024/06/Logo-neu-4-295x300.png" 
            alt="MWL GM MEMORIAL" 
            className="logo-img"
          />
          <span className="school-name">MWL GM MEMORIAL</span>
        </Link>
      </div>
      
      <button className="mobile-menu-btn" onClick={toggleMenu}>
        <span className={`hamburger ${isMenuOpen ? 'active' : ''}`}></span>
      </button>

      <nav className={`nav-links ${isMenuOpen ? 'active' : ''}`}>
        <Link to="/" onClick={toggleMenu}>Home</Link>
        <Link to="/about" onClick={toggleMenu}>About Us</Link>
        <Link to="/admission" onClick={toggleMenu}>Admission</Link>
        <div 
          className="dropdown"
          onMouseEnter={() => setIsLearningDropdownOpen(true)}
          onMouseLeave={() => setIsLearningDropdownOpen(false)}
        >
          <span className="dropdown-trigger">Learning</span>
          <div className={`dropdown-content ${isLearningDropdownOpen ? 'show' : ''}`}>
            <Link to="/early-years" onClick={toggleMenu}>Early Years</Link>
            <Link to="/primary-learning" onClick={toggleMenu}>Primary Learning</Link>
          </div>
        </div>
        <Link to="/sponsor" onClick={toggleMenu}>Sponsor A Child</Link>
        <Link to="/contact" onClick={toggleMenu}>Contact</Link>
        <Link to="/gallery" onClick={toggleMenu}>Gallery</Link>
        <Link to="/admission" className="admission-btn" onClick={toggleMenu}>
          Apply Now
        </Link>
      </nav>
    </header>
  );
}

export default Header;

