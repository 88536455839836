import React from 'react';
import '../style/Footer.css';
function Footer() {
  const year = new Date().getFullYear();
  return (
    
   <center> <footer>
      <p>&copy; {year} MWL GM MEMORIAL PRE AND PRIMARY SCHOOL. All rights reserved.</p>
    </footer></center>
  );
}

export default Footer;
