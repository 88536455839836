import React from 'react';
import '../style/Admission.css';

function Admission() {
  return (
    <div className="page admission">
      <div className="admission-hero">
        <h1>Admission</h1>
        <p>Join Our Educational Journey</p>
      </div>

      <section className="admission-intro">
        <h2>MAELEKEZO YA KUJIUNGA NA SHULE YA MWL GM MEMORIAL-2024</h2>
        <div className="key-points">
          <div className="point-card">
            <i className="fas fa-map-marker-alt"></i>
            <p>Shule ipo umbali wa KM 2 kutoka barabara kuu Arusha- Moshi (Kilala) Wilaya ya Arumeru, Kata ya Poli, kijiji cha ndatu.</p>
          </div>
          <div className="point-card">
            <i className="fas fa-calendar-alt"></i>
            <p>Mihula ya masomo yote itafuatwa utaratibu uliowekwa na wizara ya Elimu, Sayansi na Teknolojia.</p>
          </div>
          <div className="point-card">
            <i className="fas fa-file-alt"></i>
            <p>Fomu ya kujiunga shuleni inapatikana shuleni kwa gharama ya Tsh 20,000/=</p>
          </div>
        </div>
      </section>

      <section className="requirements">
        <h2>Important Requirements</h2>
        <div className="requirements-grid">
          <div className="requirement-item">
            <i className="fas fa-user-check"></i>
            <h3>Entrance Assessment</h3>
            <p>Ili kufahamu uwezo wa watoto wanaohamia kutakuwa na usaili shuleni utakaofanyika ili kujua uwezo wa mtoto.</p>
          </div>
          <div className="requirement-item">
            <i className="fas fa-heartbeat"></i>
            <h3>Health Records</h3>
            <p>Watoto wenye matatizo yeyote ya kiafya, wanatakiwa kuleta viambatanishi kutoka hospitali iliyosajiliwa na kuwasilishwa shuleni.</p>
          </div>
          <div className="requirement-item">
            <i className="fas fa-money-check"></i>
            <h3>Fee Payment</h3>
            <p>Mzazi/Mlezi unatakiwa kumaliza malipo ya ada kabla ya kuripoti shuleni</p>
          </div>
          <div className="requirement-item">
            <i className="fas fa-child"></i>
            <h3>Student Escort</h3>
            <p>Mzazi/Mlezi unatakiwa kumleta mwanao shuleni na kumchukua baada ya masomo kukamilika</p>
          </div>
        </div>
      </section>

      <section className="fee-structure">
        <h2>School Fees Structure - 2024</h2>
        <div className="fee-table-container">
          <table className="fee-table">
            <thead>
              <tr>
                <th>KIPENGELE</th>
                <th>GHARAMA</th>
                <th>AWAMU YA 1 JAN-APRIL</th>
                <th>AWAMU YA 2 MEI-AGOSTI</th>
                <th>AWAMU YA 3 SEPT-DESEMBA</th>
                <th>JUMLA</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>FEDHA YA KIINGILIO</td>
                <td>50,000</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>50,000</td>
              </tr>
              <tr>
                <td>SARE 2</td>
                <td>55,000</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>55,000</td>
              </tr>
              <tr>
                <td>SWETA,KOFIA,SOCKS</td>
                <td>35,000</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>35,000</td>
              </tr>
              <tr>
                <td>NGUO YA MICHEZO</td>
                <td>40,000</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>40,000</td>
              </tr>
              <tr>
                <td>VITABU</td>
                <td>36,000</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>36,000</td>
              </tr>
              <tr>
                <td>SHAJALA</td>
                <td>5,000</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>5,000</td>
              </tr>
              <tr>
                <td>ADA NA CHAKULA</td>
                <td>-</td>
                <td>340,000</td>
                <td>340,000</td>
                <td>340,000</td>
                <td>1,020,000</td>
              </tr>
              <tr className="total-row">
                <td>JUMLA</td>
                <td>221,000</td>
                <td>340,000</td>
                <td>340,000</td>
                <td>340,000</td>
                <td>1,241,000</td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>

      <section className="additional-requirements">
        <h2>Additional Requirements</h2>
        <div className="requirements-list">
          <div className="list-item">
            <i className="fas fa-check-circle"></i>
            <p>Ream 2</p>
          </div>
          <div className="list-item">
            <i className="fas fa-check-circle"></i>
            <p>Daftari 4 za double line, 2 za square na 2 za plain page 100</p>
          </div>
          <div className="list-item">
            <i className="fas fa-check-circle"></i>
            <p>Begi la shule</p>
          </div>
          <div className="list-item">
            <i className="fas fa-check-circle"></i>
            <p>Kiatu cheusi cha ngozi na raba za michezo</p>
          </div>
        </div>
      </section>

      <section className="payment-info">
        <h2>Payment Information</h2>
        <div className="bank-details">
          <h3><i className="fas fa-university"></i> Bank Details</h3>
          <p><strong>Account Name:</strong> MWL GM MEMORIAL PRE AND PRIMARY SCHOOL</p>
          <p><strong>Account Number:</strong> 015C871537500</p>
          <p><strong>Bank:</strong> CRDB</p>
          <div className="payment-note">
            <i className="fas fa-info-circle"></i>
            <p>Weka fedha kwenye akaunti na hakikisha unakuja na slip original kwa ajili ya kumbukumbu za shule</p>
          </div>
          <div className="warning-note">
            <i className="fas fa-exclamation-triangle"></i>
            <p>Kushindwa kukidhi masharti tajwa hapo juu, mwanao hatopokelewa shuleni.</p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Admission;
