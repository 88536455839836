import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Sponsor from './pages/Sponsor';
import Admission from './pages/Admission';
import EarlyYears from './pages/EarlyYears';
import PrimaryLearning from './pages/PrimaryLearning';
import Footer from './components/Footer';
import Gallery from './pages/Gallery';
import Watermark from './components/Watermark';
import NotFound from './pages/NotFound';
import './style/App.css';

function App() {
  return (
    <Router>
      <div className="app-container">
        <Header />
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/sponsor" element={<Sponsor />} />
            <Route path="/admission" element={<Admission />} />
            <Route path="/early-years" element={<EarlyYears />} />
            <Route path="/primary-learning" element={<PrimaryLearning />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </main>
        <Watermark />
        <Footer />
      </div>
    </Router>
  );
}

export default App;
