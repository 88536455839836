import React, { useState } from 'react';
import '../style/Gallery.css';

function Gallery() {
  const [selectedImage, setSelectedImage] = useState(null);
  const [activeCategory, setActiveCategory] = useState('all');

  const categories = [
    { id: 'all', name: 'All Photos', icon: 'fas fa-images' },
    { id: 'campus', name: 'Campus', icon: 'fas fa-school' },
    { id: 'activities', name: 'Activities', icon: 'fas fa-running' },
    { id: 'events', name: 'Events', icon: 'fas fa-calendar-alt' },
    { id: 'sports', name: 'Sports', icon: 'fas fa-futbol' }
  ];

  const images = [
    {
      url: "https://mwlgmmemorial.sc.tz/wp-content/uploads/2024/11/IMG_3975-1.webp",
      title: "Learning Activities",
      category: "activities"
    },
    {
      url: "https://mwlgmmemorial.sc.tz/wp-content/uploads/2024/11/IMG_3975-2.webp",
      title: "Classroom Learning",
      category: "activities"
    },
    {
      url: "https://mwlgmmemorial.sc.tz/wp-content/uploads/2024/11/IMG_3991-1.webp",
      title: "Student Activities",
      category: "activities"
    },
    {
      url: "https://mwlgmmemorial.sc.tz/wp-content/uploads/2024/11/IMG_3991-2.webp",
      title: "School Events",
      category: "events"
    },
    {
      url: "https://mwlgmmemorial.sc.tz/wp-content/uploads/2024/11/IMG_4021-1.webp",
      title: "Campus Life",
      category: "campus"
    },
    {
      url: "https://mwlgmmemorial.sc.tz/wp-content/uploads/2024/11/IMG_4022-1.webp",
      title: "School Facilities",
      category: "campus"
    },
    {
      url: "https://mwlgmmemorial.sc.tz/wp-content/uploads/2024/11/IMG_4061-1.webp",
      title: "Sports Activities",
      category: "sports"
    },
    {
      url: "https://mwlgmmemorial.sc.tz/wp-content/uploads/2024/11/MIP08574.webp",
      title: "School Events",
      category: "events"
    },
    {
      url: "https://mwlgmmemorial.sc.tz/wp-content/uploads/2024/11/MIP08583.webp",
      title: "Campus Views",
      category: "campus"
    },
    {
      url: "https://mwlgmmemorial.sc.tz/wp-content/uploads/2024/11/MIP08584.webp",
      title: "Student Life",
      category: "activities"
    },
    {
      url: "https://mwlgmmemorial.sc.tz/wp-content/uploads/2024/11/MIP08602.webp",
      title: "School Activities",
      category: "activities"
    },
    {
      url: "https://mwlgmmemorial.sc.tz/wp-content/uploads/2024/11/MIP08613.webp",
      title: "Sports Day",
      category: "sports"
    },
    {
      url: "https://mwlgmmemorial.sc.tz/wp-content/uploads/2024/11/MIP08618.webp",
      title: "Campus Activities",
      category: "campus"
    },
    {
      url: "https://mwlgmmemorial.sc.tz/wp-content/uploads/2024/11/MIP08689.webp",
      title: "School Events",
      category: "events"
    },
    {
      url: "https://mwlgmmemorial.sc.tz/wp-content/uploads/2024/11/MIP08699.webp",
      title: "Learning Environment",
      category: "campus"
    },
    {
      url: "https://mwlgmmemorial.sc.tz/wp-content/uploads/2024/11/MIP08725.webp",
      title: "Student Activities",
      category: "activities"
    },
    {
      url: "https://mwlgmmemorial.sc.tz/wp-content/uploads/2024/11/MIP08727.webp",
      title: "Sports Activities",
      category: "sports"
    },
    {
      url: "https://mwlgmmemorial.sc.tz/wp-content/uploads/2024/11/MIP08752.webp",
      title: "School Life",
      category: "activities"
    },
    {
      url: "https://mwlgmmemorial.sc.tz/wp-content/uploads/2024/11/MIP08753.webp",
      title: "Campus Views",
      category: "campus"
    }
  ];

  const filteredImages = activeCategory === 'all' 
    ? images 
    : images.filter(img => img.category === activeCategory);

  return (
    <div className="page gallery">
      <div className="gallery-hero">
        <h1>Our Gallery</h1>
        <p>Capturing Moments of Learning and Growth</p>
      </div>

      <div className="gallery-categories">
        {categories.map(category => (
          <button
            key={category.id}
            className={`category-btn ${activeCategory === category.id ? 'active' : ''}`}
            onClick={() => setActiveCategory(category.id)}
          >
            <i className={category.icon}></i>
            {' '}
            {category.name}
            {' '}
            <span className="count">
              ({category.id === 'all' 
                ? images.length 
                : images.filter(img => img.category === category.id).length})
            </span>
          </button>
        ))}
      </div>

      <div className="gallery-container">
        {filteredImages.map((image, index) => (
          <div 
            key={index} 
            className="gallery-item"
            onClick={() => setSelectedImage(image)}
          >
            <img src={image.url} alt={image.title} />
            <div className="image-overlay">
              <h3>{image.title}</h3>
              <p>{categories.find(cat => cat.id === image.category)?.name}</p>
            </div>
          </div>
        ))}
      </div>

      {selectedImage && (
        <div className="lightbox" onClick={() => setSelectedImage(null)}>
          <span className="close-btn">&times;</span>
          <img src={selectedImage.url} alt={selectedImage.title} />
          <div className="image-info">
            <h3>{selectedImage.title}</h3>
            <p>{categories.find(cat => cat.id === selectedImage.category)?.name}</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default Gallery;
